import "./styles.scss"

import React from "react"

const Categories = ({
  categories_list,
  currentCategory,
  setCurrentCategory,
}) => {
  const handleClick = category => {
    if (currentCategory === category) {
      setCurrentCategory(null)
    } else {
      setCurrentCategory(category)
    }
  }

  return (
    <section className="blog-categories">
      <div className="container">
        <div className="blog-categories__list--wrapper">
          <p className="blog-categories__list-desc">Pokaż wpisy w kategorii</p>
          <div className="blog-categories__list-items">
            <button
              type="button"
              className={`blog-categories__list-item${
                currentCategory === null ? " active" : ""
              }`}
              onClick={() => setCurrentCategory(null)}
            >
              <span className="blog-categories__list-item-name">Wszystkie</span>
            </button>
            {categories_list?.map((category, index) => {
              return (
                <button
                  key={index}
                  type="button"
                  className={`blog-categories__list-item${
                    currentCategory === category.name ? " active" : ""
                  }`}
                  onClick={() => handleClick(category.name)}
                >
                  <span className="blog-categories__list-item-name">
                    {category.name}
                  </span>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Categories
