import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const ArticleSingleTail = ({ title, href, img }) => {
  return (
    <Link to={href} className="article-single-tail">
      <div className="article-single-tail__image">
        <img src={img?.sourceUrl} alt={img?.altText} />
        <p>{title}</p>
      </div>
    </Link>
  )
}

export default ArticleSingleTail
