import "./styles.scss"

import React from "react"

import Button from "components/Button"
import ArticleSingleTail from "components/ArticleSingleTail"

const BlogTails = ({
  posts_to_show,
  showAllPosts,
  setShowAllPosts,
  show_button,
}) => {
  return (
    <section className="blog-tails">
      <div className="container">
        <div className="row">
          {posts_to_show?.map(({ id, acfPosts, slug, title }) => {
            return (
              <div key={id} className="col-lg-3 col-md-4 col-sm-6 col-12">
                <ArticleSingleTail
                  title={title}
                  href={`/blog/${slug}/`}
                  img={acfPosts?.image}
                />
              </div>
            )
          })}
        </div>
        {show_button > 8 && (
          <Button
            type="button"
            className={`button-dark-green${showAllPosts ? " hide" : ""}`}
            onClick={() => setShowAllPosts(true)}
          >
            Pokaż więcej wpisów
          </Button>
        )}
      </div>
    </section>
  )
}

export default BlogTails
