import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import SliderArrow from "components/Icons/SliderArrow/slider-arrow"

const NewArticle = ({ new_article }) => {
  const { title, excerpt, slug, acfPosts } = new_article

  return (
    <div className="blog-new-article">
      <div className="container">
        <Link to={`/blog/${slug}/`}>
          <div className="blog-new-article--wrapper">
            <div className="blog-new-article__image">
              <img
                src={acfPosts?.image?.sourceUrl}
                alt={acfPosts?.image?.altText}
              />
              <span className="blog-new-article__badge">Najnowszy</span>
            </div>
            <div className="blog-new-article__content">
              <p className="blog-new-article__title">{title}</p>
              <div
                className="blog-new-article__excerpt"
                dangerouslySetInnerHTML={{ __html: excerpt }}
              />
              <div className="blog-new-article__cta">
                <span className="blog-new-article__cta-text">Czytaj dalej</span>
                <div className="blog-new-article__cta-arrow">
                  <SliderArrow />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default NewArticle
